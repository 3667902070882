import * as React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import styled from '../../helpers/styled';
import { Roaster, FullRoasterInfo } from '../../models/roasters';
import themeColor from '../../helpers/themeColor';
import { colors } from '../../constants';
import { TestResult, Ping } from '../Roaster';
import Button from '../UI/Button';
import { NavLink } from 'react-router-dom';

const Row = styled('div')<{ color: string }>`
box-sizing: border-box;
display: flex;
background: ${props => props.color};
flex-direction: row;
align-items: center;
justify-content: space-between;
font-size: 15px;
line-height: 20px;
padding: 4px;
-webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
-moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
&:not(:last-child) {
  margin-bottom: 2px;
}
span.heading {
  color: #7876E0;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}
div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.lastCleaning {
  flex: 7;
  border-right: 1px solid transparent;
  text-align: center;
  border-right: 1px solid ${themeColor('black')};
}
.createdAt {
  flex: 7;
  border-right: 1px solid ${themeColor('black')};
  text-align: center;
}
.hostname {
  flex: 5;
  font-weight: bold;
  border-right: 1px solid ${themeColor('black')};
  overflow: hidden;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.port {
  flex: 9;
  border-right: 1px solid ${themeColor('black')};
}
.netspeed {
  flex: 10;
  border-right: 1px solid ${themeColor('black')};
  span {
    font-size: 14px;
    display: inline-block;
    flex: 1;
    color: ${themeColor('secondary')};
    border-right: 1px solid ${themeColor('primary')};
  }
  span:last-child {
    border-right-color: transparent;
  }
}
.customer {
  flex: 13;
  border-right: 1px solid ${themeColor('black')};
}
.test {
  flex: 10;
  color: ${themeColor('secondary')};
  border-right: 1px solid ${themeColor('black')};
}
.actions {
  width: 125px;
  ${Button} {
    height: 30px;
    width: 84px;
    padding: 6px;
    margin-bottom: 0;
  }
}
`;

export const HeadingRow = styled.div`
display: flex;
background: white;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 4px;
font-weight: bold;
color: #7876E0;
font-size: 15px;
line-height: 20px;
box-sizing: border-box;
font-weight: bold;
div {
  text-align: center;
}
.lastCleaning, .createdAt {
  flex: 7;
}
.hostname {
  flex: 5;
}
.port {
  flex: 9;
}
.customer {
  flex: 13;
}
.test, .netspeed {
  flex: 10;
}
div.actions {
  width: 125px;
}
`;

interface RoasterRowProps {
  color: string;
  roaster: FullRoasterInfo;
  onClick?: () => void;
  onClickPuttyLogs: () => void;
}
export const RoasterRow: React.FunctionComponent<RoasterRowProps> = props => {
  const ping = get(props.roaster.networkSnap, 'ping')
    ? props.roaster.networkSnap.ping.toFixed(2) : 'None';
  const download = get(props.roaster.networkSnap, 'download')
    ? props.roaster.networkSnap.download.toFixed(2) : 'None';
  const upload = get(props.roaster.networkSnap, 'upload')
    ? props.roaster.networkSnap.upload.toFixed(2) : 'None';
  const lastCleaning = props.roaster.lastMaintenanceEvent
    ? moment(props.roaster.lastMaintenanceEvent.createdAt).format('MM/DD HH:mm') : 'None';
  return (
    <Row color={props.color} onClick={props.onClick}>
      <div className="hostname"><span>{props.roaster.Host}</span></div>
      <div className="port">{props.roaster.Port}</div>
      <div className="netspeed">
        <span><Ping ping={ping} /></span>
        <span style={{ color: download === 'None' ? colors.red : colors.green }}>{download}</span>
        <span style={{ color: upload === 'None' ? colors.red : colors.green }}>{upload}</span>
      </div>
      <div className="customer">{props.roaster.customer}</div>
      <div className="test"><TestResult passed={props.roaster.passedLastTest} /></div>
      <div className="createdAt">{moment(props.roaster.Timestamp).format('MM/DD/YYYY hh:mm A')}</div>
      <div className="createdAt">{`${props.roaster.customerSatisfaction.toFixed(0)} %`}</div>
      <div className="lastCleaning">{lastCleaning}</div>
      <div className="actions">
        <Button onClick={props.onClickPuttyLogs}>Putty Log</Button>
      </div>
    </Row>
  );
};

export const RoasterHeading: React.FunctionComponent<{}> = React.memo(props => {
  return (
    <HeadingRow>
      <div className="hostname">Name</div>
      <div className="port">Port</div>
      <div className="netspeed">Network Speed</div>
      <div className="customer">Customer</div>
      <div className="test">Self Test</div>
      <div className="createdAt">Created</div>
      <div className="createdAt">Happiness</div>
      <div className="lastCleaning">Last Chaff Cleaning</div>
      <div className="actions"></div>
    </HeadingRow>
  );
})

export interface RoasterTablePrpos {
  roasters: Roaster[];
}
const RoasterTable: React.FunctionComponent<RoasterTablePrpos> = React.memo(props => {
  return (
    <>
      {/* {props.roasters.map((roaster, index) => <RoasterRow roaster={roaster} color={index % 2 === 0 ? colors.page : colors.white} />)} */}
    </>
  );
});
export default RoasterTable;