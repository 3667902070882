import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { Dictionary } from 'lodash';
import { SignInPayload } from '../models/auth';
import { PuttyLogItem } from '../models/putty';
import config from '../config/config';

export class Api {
  axiosInstance: AxiosInstance;
  baseUrl: string;
  headers: Dictionary<string> = {
    'Content-Type': 'application/json',
  };
  constructor(url: string) {
    this.baseUrl = url;
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 90000,
      headers: this.headers,
    });
  }

  setHeaders = (key: string, value: string) => {
    this.axiosInstance.defaults.headers[key] = value;
    return this;
  }

  get<T>(path: string, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.get<T>(path, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })
  }
  post<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })
  }
  put<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.put<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })
  }

  patch<T>(path: string, body?: any, opts?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.patch<T>(path, body, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    })

  }
  delete(path: string, opts?: AxiosRequestConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.delete(path, opts)
        .then(response => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response));
    });
  }
}

class BWApi extends Api {
  signIn = (email, password): Promise<SignInPayload | null> => {
    return new Promise((resolve, reject) => {
      this.post<SignInPayload>('/auth/signIn', { email, password })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  }
}


export const BW = new BWApi(config.bw_api_url);
export const Tower = new Api(config.tower_api_url);
export const PuttyAPI = {
  getLogsForMachine: (machineId: string): Promise<PuttyLogItem[]> => {
    return Tower.get<{ logItems: PuttyLogItem[] }>('/services/v1/puttylogs', { params: { machineId } })
      .then(logs => logs.logItems)
      .catch(error => error)
  }
}
