import { colors } from "../constants";

export default function (state: number) {
  switch(state) {
    case 4:
      return '#F4A745'; // pre-heat
    case 5:
      return '#F82800'; // roast
    case 6:
      return '#A1E3FF'; // cool
    default:
      return colors.white;
  };
}