import * as _ from 'lodash';
import * as actions from '../constants/netSpeed';
import { NetSpeedItem } from '../../models/roasters';
import { Tower } from '../../api';

export const getNetSpeed = () => dispatch => {
  dispatch({ type: actions.GET_NETSPEED });
  Tower.get<NetSpeedItem[]>('/netspeed')
    .then(netSpeed => {
      dispatch({ type: actions.GET_NETSPEED_SUCCESS, data: { netSpeed } });
    })
    .catch(error => dispatch({ type: actions.GET_NETSPEED_FAILED, data: { error } }));
}