import * as styledComponents from "styled-components";
import { Dictionary } from "lodash";
import { colors } from "../constants";

export interface ThemeInterface {
  pallette: typeof colors;
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
