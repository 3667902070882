import { MarkerData } from "../redux/selectors/mapMarkers";

// https://www.geodatasource.com/developers/javascript

export default function (marker: MarkerData, compare: MarkerData): number {
  const { latitude: lat1, longitude: lon1 } = marker;
  const { latitude: lat2, longitude: lon2 } = compare;
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
}