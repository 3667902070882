import * as React from 'react';
import styled from '../../../helpers/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStore } from '../../../models/store';
import { setSortOption, setSortType } from '../../../redux/actions';
import { connect } from 'react-redux';
import { FullRoasterInfo } from '../../../models/roasters';

const SortContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
&.buttons {
  margin-left: 23px;
}
`;

const SortButton = styled('button')<{ active?: boolean }>`
height: 22px;
width: 22px;
margin: 5px;
border-radius: 2px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid ${props => props.active ? '#7C7CB6' : '#D2C4B3'};
background: ${props => props.active ? '#7C7CB6' : '#FFFFFF'};
`;

const SelectContainer = styled.div`
display: flex;
flex-direction: row;
position: relative;
cursor: pointer;
line-height: 24px;
strong {
  font-weight: bold;
}
`;

const SelectDropdown = styled.div`
min-width: 110%;
min-height: 50px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: stretch;
position: absolute;
top: 28px;
left: 0;
padding: 8px;
text-align: left;
background: #FFFFFF;
border-radius: 0 0 2px 2px;
box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
strong, span {
  display: block;
  width: 100%;
  cursor: pointer;
  margin: 7px 0;
}
`;

const SortTypeSelect: React.FunctionComponent<any> = React.memo(props => {
  const [isOpen, toggleOpen] = React.useState(false);
  const onClick = () => {
    toggleOpen(!isOpen);
  }
  const label = props.selectedOption.label;
  return (
    <SelectContainer onClick={onClick}>
      <strong>Sort by </strong>&nbsp;
      <span>{label} <FontAwesomeIcon color="#414042" size="lg" icon={isOpen ? 'angle-up' : 'angle-down'} /></span>
      {
        isOpen && (
          <SelectDropdown>
            {
              props.options.map(opt => {
                const isSelected = props.selectedOption.id === opt.id;
                return isSelected
                  ? <strong onClick={props.onSelectOption(opt)} key={opt.id}>{opt.label}</strong>
                  : <span onClick={props.onSelectOption(opt)} key={opt.id}>{opt.label}</span>;
              })
            }
          </SelectDropdown>
        )
      }
    </SelectContainer>
  )
});

export type SortOption = {
  id: string;
  label: string;
  callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => 1 | 0 | -1;
}

export interface SortControlProps {
  options: SortOption[];
  selectedType: 'asc' | 'desc';
  selectedOption: SortOption;
  setSelectedOption: (opt: SortOption) => () => any;
  setSelectedType: (type: 'asc' | 'desc') => () => any;
}

const SortControl: React.FunctionComponent<SortControlProps> = React.memo(props => {
  return (
    <SortContainer>
      <SortTypeSelect
        options={props.options}
        selectedOption={props.selectedOption}
        onSelectOption={props.setSelectedOption}
      />
      <SortContainer className="buttons">
        <SortButton onClick={props.setSelectedType('asc')} active={props.selectedType === 'asc'}>
          <FontAwesomeIcon color={props.selectedType === 'asc' ? '#FFFFFF' : '#D2C4B3'} size="lg" icon="angle-down" />
        </SortButton>
        <SortButton onClick={props.setSelectedType('desc')} active={props.selectedType === 'desc'}>
          <FontAwesomeIcon color={props.selectedType === 'desc' ? '#FFFFFF' : '#D2C4B3'} size="lg" icon="angle-up" />
        </SortButton>
      </SortContainer>
    </SortContainer>
  );
});

const mapStateToProps = (state: AppStore) => ({
  options: state.sort.availableOptions,
  selectedType: state.sort.selectedType,
  selectedOption: state.sort.selectedOption,
});
const mapDispatchToProps = dispatch => ({
  setSelectedType: (type: 'asc' | 'desc') => () => dispatch(setSortType(type)),
  setSelectedOption: (opt: SortOption) => () => dispatch(setSortOption(opt)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SortControl);
