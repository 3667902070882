import * as _ from 'lodash';
import * as actions from '../constants/stores';
import { BaseStore } from '../../models/core';
import { BW } from '../../api';

export const getStores = () => dispatch => {
  dispatch({ type: actions.GET_STORES });
  BW.get<BaseStore[]>('/stores')
    .then(stores => {
      dispatch({ type: actions.GET_STORES_SUCCESS, data: { stores } });
    })
    .catch(error => dispatch({ type: actions.GET_STORES_FAILED, data: { error } }));
}