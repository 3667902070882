import { combineReducers, Reducer } from 'redux';
import roasters from './roasters';
import selfTest from './selfTest';
import netSpeed from './netSpeed';
import roastEvents from './roastEvents';
import maintenanceEvents from './maintenanceEvents';
import auth from './auth';
import sort from './sort';
import cafes from './cafes';
import stores from './stores';
import customers from './customers';
import map from './map';
import { AppStore } from '../../models/store';

const appState: Reducer<AppStore, any> = combineReducers({
  auth,
  sort,
  roasters,
  selfTest,
  netSpeed,
  roastEvents,
  maintenanceEvents,
  cafes,
  stores,
  customers,
  map,
});
export default appState;
