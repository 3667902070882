import * as React from 'react';
import styled from '../../helpers/styled';
import { SelfTestItem, FullRoasterInfo } from '../../models/roasters';
import moment from 'moment';
import _ from 'lodash';
import { Table } from '../UI';
import themeColor from '../../helpers/themeColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TestSuite from '../TestSuite';
import { RoasterRow } from '../RoasterTable';
import ScrollableTable from '../UI/ScrollableTable';
import { colors } from '../../constants';
import { withRouter, RouteChildrenProps } from 'react-router';
import RoastList from '../RoastList';
import { CustomerSatisfactionType } from 'models/core';

const Container = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`;

const TabContent = styled.div`
box-sizing: border-box;
padding: 20px 10px;
min-width: 300px;
max-width: 900px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const Title = styled.div`
/* align-self: stretch; */
display: flex;
flex-direction: column;
justify-content: center;
padding: 10px;
background: ${props => props.theme.pallette.secondary};
div.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
h5 {
  color: ${props => props.theme.pallette.white};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-block-end: 0;
  margin-block-start: 0;
}
`;

const Content = styled.div`
padding: 14px;
flex: 1;
background: rgba(237,234,228,0.2);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 400px;
overflow-x: hidden;
overflow-y: scroll;
${Table} {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}
`;

const SerialContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
color: ${props => props.theme.pallette.white};
.label {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}
.serial {
  font-size: 12px;
  font-weight: bold;
}
`;


const Tabs = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
`;

const TabButton = styled('button') <{ isActive?: boolean }>`
font-size: 16px;
line-height: 22px;
margin: 0;
width: 190px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
color: #7876E0;
padding: 10px 0;
font-weight: ${props => props.isActive ? 'bold' : 'normal'};
background: transparent;
border: none;
border-left: 1px solid ${props => props.isActive ? '#7876E0' : '#3A3333'};
border-right: 1px solid ${props => props.isActive ? '#7876E0' : '#3A3333'};
border-bottom: 2px solid ${props => props.isActive ? '#7876E0' : '#3A3333'};
cursor: pointer;
&:first-child {
  border-left: none;
}
&:last-child {
  border-right: none;
}
&:active, &:focus {
  outline: none;
}
`;

const PingText = styled('span') <{ fontSize?: string; ping?: number | string | null }>`
font-size: ${props => props.fontSize ? props.fontSize : 'inherit'};
font-weight: bold;
color: ${
  props => !props.ping || Number(props.ping) >= 90
    ? themeColor('red')(props) : themeColor('green')(props)
  };
`;

export const Ping: React.FunctionComponent<{ fontSize?: string; ping?: number | string | null }> = props => {
  return <PingText {...props}>{props.ping || "None"}</PingText>
}

const TestResultContainer = styled('div') <{ passed: boolean }>`
`;

export const TestResult: React.FunctionComponent<{ passed: boolean | null }> = props => {
  let content = props.passed ? (
    <span style={{ color: colors.green }}>
      PASSED
    </span>
  ) : (
      <span style={{ color: colors.red }}>
        FAILED
    </span>
    );
  if (!_.isBoolean(props.passed)) {
    content = (
      <span style={{ color: colors.red }}>
        NONE
      </span>
    )
  }
  return (
    <TestResultContainer {...props}>
      {content}
    </TestResultContainer>
  );
}

export const LabeledInfo: React.FunctionComponent<{
  label: string;
  value?: string | number | React.ReactNode | null;
}> = props => {
  return (
    <SerialContainer>
      <span className="label">{props.label}</span>
      <div className="serial">{props.value || 'None'}</div>
    </SerialContainer>
  );
}

export interface RoasterProps extends RouteChildrenProps {
  info: FullRoasterInfo;
  color: string;
}

interface State {
  isOpen: boolean;
  activeTab: number;
}

class Roaster extends React.Component<RoasterProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTab: 0,
    };
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen })

  goToLogs = () => this.props.history.push(`/putty/${this.props.info.Host}`)

  setActiveTab = (activeTab: number) => this.setState({ activeTab })

  tabs = () => {
    const { activeTab } = this.state;
    return (
      <Tabs>
        <TabButton isActive={activeTab === 0} onClick={() => this.setActiveTab(0)}>Self Tests</TabButton>
        <TabButton isActive={activeTab === 2} onClick={() => this.setActiveTab(2)}>Roast List</TabButton>
        <TabButton isActive={activeTab === 1} onClick={() => this.setActiveTab(1)}>Net Speed</TabButton>
        <TabButton isActive={activeTab === 3} onClick={() => this.setActiveTab(3)}>Roasts</TabButton>
        <TabButton isActive={activeTab === 4} onClick={() => this.setActiveTab(4)}>Support Incidents</TabButton>
      </Tabs>
    );
  }

  renderActiveTab = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 2:
        return this.renderRoastList()
      case 1:
        return this.renderNetSpeedTab();
      case 3: return this.renderRoastsTab();
      case 4: return this.renderCustomerFeedback();
      case 0:
      default:
        return this.renderSelfTestsTab();
    }
  }

  renderRoastList = () => {
    return (
      <TabContent>
        <RoastList machineId={this.props.info.Host} />
      </TabContent>
    )
  }

  renderRoastsTab = () => {
    if (!this.props.info.roasts.length) {
      return (
        <p>No data available...</p>
      )
    }
    return (
      <ScrollableTable maxWidth={900}>
        <thead>
          <tr>
            <th align="left">Date</th>
            <th>Roaster</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.info.roasts
              .sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)
              .slice(0, 50)
              .map((itm, i) => (
                <tr key={`netspeed_table_row_${i}_${_.get(itm, '_id')}`}>
                  <td align="left">{moment(itm.date).format('MM/DD/YYYY hh:mm A')}</td>
                  <td>{itm.roaster || 'No roaster specified'}</td>
                  <td>{itm.state}</td>
                </tr>
              ))
          }
        </tbody>
      </ScrollableTable>
    )
  }

  renderNetSpeedTab = () => {
    if (!this.props.info.netSpeed.length) {
      return (
        <p>No data available...</p>
      )
    }
    return (
      <ScrollableTable maxWidth={900}>
        <thead>
          <tr>
            <th align="left">Date</th>
            <th>
              <div className="column">
                <span>Ping</span>
                <span>Upload</span>
                <span>Download</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.info.netSpeed
              .sort((a, b) => new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1)
              .slice(0, 50)
              .map((itm, i) => itm.server ? (
                <tr key={`netspeed_table_row_${i}_${_.get(itm, '_id')}`}>
                  <td align="left">{moment(itm.timestamp).format('MM/DD/YYYY hh:mm A')}</td>
                  <td>
                    <div className="column">
                      <span><Ping ping={itm.server.ping} /></span>
                      <span>{itm.speeds.upload}</span>
                      <span>{itm.speeds.download}</span>
                    </div>
                  </td>
                </tr>
              ) : null)
          }
        </tbody>
      </ScrollableTable>
    )
  }

  renderSelfTestsTab = () => {
    if (!this.props.info.testSuites.length) {
      return (
        <p>No data available...</p>
      )
    }
    return (
      <ScrollableTable maxWidth={900}>
        <thead>
          <tr>
            <th align="left">Date</th>
            <th>Tests</th>
            <th>Passed</th>
          </tr>
        </thead>
        <tbody>
          {this.props.info.testSuites.map((itm, i) => (
            <tr key={`netspeed_table_row_${i}_${_.get(itm, '_id')}`}>
              <td align="left">{moment(itm.start).format('MM/DD/YYYY hh:mm A')}</td>
              <td align="center">{`${itm.stages.length} tests ran`}</td>
              <td align="center"><TestResult passed={itm.passed} /></td>
            </tr>
          ))}
        </tbody>
      </ScrollableTable>
    )
  }

  renderCustomerFeedback = () => {
    if (!this.props.info.incidents.length) {
      return (
        <p>No data available...</p>
      )
    }
    return (
      <ScrollableTable maxWidth={900}>
        <thead>
          <tr>
            <th align="left">Date</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {this.props.info.incidents.map((itm, i) => (
            <tr key={`netspeed_table_row_${i}_${_.get(itm, '_id')}`}>
              <td align="left">{moment(itm.createdAt).format('MM/DD/YYYY hh:mm A')}</td>
              <td align="center">{CustomerSatisfactionType[itm.type]}</td>
            </tr>
          ))}
        </tbody>
      </ScrollableTable>
    )
  }

  render() {
    return (
      <Container>
        <RoasterRow
          color={this.props.color}
          roaster={this.props.info}
          onClick={this.toggleOpen}
          onClickPuttyLogs={this.goToLogs}
        />
        {
          this.state.isOpen &&
          <Content>
            {this.tabs()}
            {this.renderActiveTab()}
          </Content>
        }
      </Container>
    );
  }
}
export default withRouter(Roaster);
