import * as React from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Switch, Link, NavLink, withRouter } from 'react-router-dom';
import styled from '../../helpers/styled';
import themeColor from '../../helpers/themeColor';
import ReportsContainer from '../../containers/Reports';
import SelfTestsContainer from '../../containers/SelfTests';
import LiveMapContainer from '../../containers/LiveMap';
import Logo from '../../assets/logo_header.png';
import PrivateRoute from '../../components/PrivateRoute';
import { AppStore } from '../../models/store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions';
import { User } from '../../models/auth';
import PuttyLogsContainer from '../../containers/PuttyLogs';
import RoastLogsContainer from '../../containers/RoastLogs';

const Header = styled.header`
display: flex;
align-items: center;
justify-content: center;
>div {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
}
.brand {
  img {
    height: 58px;
    width: 198px;
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.profile {
  color: ${themeColor('white')};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid white;
  a {
    color: ${themeColor('white')};
    text-decoration: none;
  }
}
`;
const Sidebar = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: stretch;
padding: 20px;
a {
  padding: 10px 0;
  text-decoration: none;
  color: ${themeColor('white')};
}
`;
const Content = styled.div``;
const Container = styled.div<{ isFullscreen?: boolean }>`
flex: 1;
display: grid;
grid-template-columns: 1fr;
${props => !props.isFullscreen && 'grid-template-rows: 100px 1fr'}; 
${props => !props.isFullscreen && "grid-template-areas: 'header' 'main'"};
${Header} {
  grid-area: header;
  background-color: ${themeColor('primary')};
}
${Sidebar} {
  grid-area: sidebar;
  background-color: ${themeColor('secondary')};
}
${Content} {
  grid-area: main;
  background-color: ${themeColor('page')};
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}
`;

const NavItem = styled(NavLink)`
text-decoration: none;
line-height: 24px;
font-size: 18px;
font-weight: bold;
color: ${themeColor('white')};
:not(:last-child) {
  margin-right: 10px;
}
&.active {
  color: ${themeColor('accent')};
}
`;

class AppLayout extends React.Component<any> {
  // componentDidMount() {
  //   if (!this.props.isLoggedIn) {
  //     this.props.history.push('/login');
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
  }

  get userName() {
    const userInfo: User = this.props.userInfo;
    if (userInfo) {
      return userInfo.name;
    }
    return 'Not Logged In';
  }
  
  signOut = (e) => {
    e.preventDefault();
    this.props.signOut();
    this.props.history.push('/login');
  }

  render() {
    return (
      <Router>
        <Container isFullscreen={this.props.isFullscreen}>
          {!this.props.isFullscreen &&
            <Header>
              <div>
                <div className="brand">
                  <Link to="/">
                    <img src={Logo} width={190} height={52} />
                  </Link>
                </div>
                <div className="content">
                  <NavItem exact to="/">Roasters</NavItem>
                  <NavItem exact to="/map">Live Map</NavItem>
                </div>
                <div className="profile">{this.userName} <a href="#" onClick={this.signOut}><FontAwesomeIcon icon="sign-out-alt" /></a></div>
              </div>
            </Header>
          }
          <Switch>
            <PrivateRoute exact path="/" component={ReportsContainer} />
            <PrivateRoute exact path="/selftests" component={SelfTestsContainer} />
            <PrivateRoute exact path="/map" component={LiveMapContainer} />
            <PrivateRoute exact path="/putty/:machineId" component={PuttyLogsContainer} />
            <PrivateRoute exact path="/roastLog/:log" component={RoastLogsContainer} />
          </Switch>
        </Container>
      </Router>
    );
  }
}
const mapStateToProps = (state: AppStore) => ({
  isLoggedIn: state.auth.isAuthenticated,
  userInfo: state.auth.userInfo,
  isFullscreen: state.map.isFullscreen
})
const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
export default enhance(AppLayout);
