import { get } from 'lodash';
import { createSelector } from 'reselect';
import { AppStore } from '../../models/store';
import { RoastEvent } from '../../models/roasters';
import distance from '../../helpers/distance';

export interface MarkerData {
  hostname: string;
  // customer: string;
  lastImage: string | null;
  latitude: number | null;
  longitude: number | null;
  lastEvent: RoastEvent;
  state: number;
  eventId: string;
  offsetLeft?: number;
}

export interface MarkersAndClusters {
  markers: MarkerData[];
  clusters: MarkerData[][];
}
const roastersSelector = (state: AppStore) => state.roasters.roasters;
const storesSelector = (state: AppStore) => state.stores.stores.filter(store => !!store.roaster);
const roastEventsSelector = (state: AppStore) => state.roastEvents.events;

export const mapMarkers = createSelector(
  roastersSelector,
  storesSelector,
  roastEventsSelector,
  (roasters, stores, roastEvents): MarkerData[] => {
    let clusters = [];
    const markers = roasters
      .map(roaster => {
        const events = roastEvents.filter(evt => evt.machine === roaster.Host).sort((a, b) => {
          return new Date(a.updatedAt).valueOf() > new Date(b.updatedAt).valueOf() ? -1 : 1;
        });
        const [lastEvent] = events;
        const lastImage = events.reduce((image, itm) => {
          const itmImage = get(itm, 'imageUrl', null);
          return !itmImage ? image : itmImage;
        }, null);
        const store = stores.find(item => item.roaster === roaster.Host);
        const latitude: number = get(store, 'latitude', null);
        const longitude: number = get(store, 'longitude', null);
        return {
          latitude,
          longitude,
          state: get(lastEvent, 'state', null),
          eventId: get(lastEvent, '_id'),
          hostname: roaster.Host,
          lastEvent: lastEvent || null,
          lastImage: lastImage || null,
        };
      })
      .filter(itm => itm.latitude !== null && itm.longitude !== null)
      .map((itm, index, array) => {
        const closeMarkers = array.filter(i => distance(i, itm) <= 0.1);
        if (closeMarkers.length > 0) {
          const currentItemIndex = closeMarkers.findIndex(i => i.hostname === itm.hostname);
          const offsetLeft = Math.abs(currentItemIndex * 40);
          return {
            ...itm,
            offsetLeft,
          };
        }
        return {
          ...itm,
          offsetLeft: 0,
        };
      })

    return markers;
  }
);

const isLoadingRoastersSelector = (state: AppStore) => state.roasters.isLoading;
// const isLoadingNetSpeedSelector = (state: AppStore) => state.netSpeed.isLoading;
const isFetchingEvents = (state: AppStore) => state.roastEvents.isLoading;

export const isLoadingMarkerData = createSelector(
  isLoadingRoastersSelector,
  // isLoadingNetSpeedSelector,
  isFetchingEvents,
  (roasters, events) => {
    return (
      roasters || events
    );
  },
);