import * as React from 'react';
import { connect, Provider } from 'react-redux';
import Parse from 'parse';
import styled, { ThemeProvider } from './helpers/styled';
import { theme } from './constants';
import AppLayout from './screens/Dashboard';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import configStore from './redux/store/configStore';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './containers/Login';
import PrivateRoute from './components/PrivateRoute';
import { PersistGate } from 'redux-persist/integration/react';
import { AppStore } from 'models/store';
import { signInByToken } from 'redux/actions/auth';
import config from './config/config'
import { User } from 'models';

library.add(fas)

Parse.serverURL = config.back4app_url;
Parse.liveQueryServerURL = config.back4app_wss_url;
Parse.initialize(config.back4app_key_id, config.back4app_secret);

const AppContainer = styled.div`
  flex: 1;
  display: flex;
  background: ${props => props.theme.pallette.white};
`;

const { store, persistor } = configStore();
class MainRouter extends React.Component<{ isAuthenticated: boolean, userInfo: User | null, refreshToken: () => void }> {
  componentDidMount() {
    if (this.props.isAuthenticated && !this.props.userInfo) {
      this.props.refreshToken();
    }
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/" component={AppLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state: AppStore) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  userInfo: state.auth.userInfo,
});
const mapDispatchToProps = dispatch => ({
  refreshToken: () => dispatch(signInByToken()),
});
const ConnectedRouter = connect(mapStateToProps, mapDispatchToProps)(MainRouter);
class App extends React.Component<any> {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <AppContainer>
              <ConnectedRouter />
            </AppContainer>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

