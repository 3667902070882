import { get } from 'lodash';
import { StoresReducer } from "../../models/store";
import * as actions from '../constants/stores';
import { BaseStore } from "../../models/core";

const initialState: StoresReducer = {
  isLoading: false,
  stores: [],
};
const stores = (state: StoresReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_STORES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STORES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_STORES_SUCCESS: {
      const stores: BaseStore[] = get(data, 'stores', []);
      return {
        ...state,
        isLoading: false,
        stores,
      };
    }
    default:
      return state;
  }
}
export default stores;
