import styled from "../../helpers/styled";

export const Container = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
`;

export const Content = styled.div`
width: 1000px;
margin: 0 auto;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`;

export const Table = styled("table")<{ noMargin?: boolean }>`
flex: 1;
margin: ${props => props.noMargin ? '0' : '10px 0'};
width: 100%;
border-collapse: collapse;
background: ${props => props.theme.pallette.page};
thead {
  background: ${props => props.theme.pallette.primary};
  color: ${props => props.theme.pallette.white};
  td {
    padding: 5px 10px;
  }
}
td {
  border: 1px solid ${props => props.theme.pallette.secondary};
  padding: 5px 10px;
}
td:nth-child(odd) {
  background: ${props => props.theme.pallette.white};
}
`;