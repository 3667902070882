import uuid from 'uuid/v4';
import { get, isBoolean, isNumber, isString } from 'lodash';
import { createSelector } from 'reselect';
import { AppStore } from '../../models/store';
import { SelfTestItem } from '../../models/roasters';

const selfTestsSelector = (state: AppStore) => state.selfTest.selfTests;

export interface SelfTestSuite {
  hostname: string;
  start: number;
  end: number;
  passed: boolean;
  stages: SelfTestItem[];
}

const testSuites = createSelector(
  selfTestsSelector,
  (tests): SelfTestSuite[] => {
    return [...tests]
      .filter((itm, index, src) => {
        if (
          !itm
          || !get(itm, 'stage_id')
          || !get(itm, 'passed')
          || !get(itm, 'timestamp')
        ) {
          return false;
        }
        const validation = get(itm, 'timestamp') && isNumber(get(itm, 'stage_id')) && isBoolean(get(itm, 'passed'))
        const prevItem = src[index - 1];
        return get(itm, 'stage_id') === 17 // problematic test stage - creates duplicates, this is a workaround.
          ? validation && get(prevItem, 'stage_id') !== 17 : validation;
      })
      .map(itm => { // perform the required validation to make sure test stage passed or failed
        // itm.passed is actually the response.ok from the http request performed by pytest to the roaster internal api
        // http://docs.python-requests.org/en/master/api/#requests.Response.ok
        // Since the tavern external function doesn't have a clear way to access the result of the test we need to basicall
        // re-perform the same checks made by tavern against the response.body to make sure the test passed or failed
        let passed = get(itm, 'passed', false);
        const res = get(itm, 'fullResponse', {});
        const message = get(res, 'message');
        const value = get(res, 'value');
        switch (get(itm, 'stage_id')) {
          case 1:
            passed = message === 'OK' && isString(value);
            break;
          case 2:
            passed = message === 'OK' && Number(value) === 3;
            break;
          case 4:
            passed = message === 'OK' && Number(value) === 150;
            break;
          case 6:
            passed = message === 'OK' && Number(value) === 4;
            break;
          case 8: {
            const roasterProfileOk = Number(get(value, 'time')) === 5 && get(value, 'temp') === 200;
            passed = message === 'OK' && roasterProfileOk;
            break;
          }
          case 10: {
            const roasterProfileOk = Number(get(value, 'time')) === 5 && get(value, 'temp') === 220;
            passed = message === 'OK' && roasterProfileOk;
            break;
          }
          case 12: {
            const roasterProfileOk = Number(get(value, 'time')) === 5 && get(value, 'temp') === 240;
            passed = message === 'OK' && roasterProfileOk;
            break;
          }
          case 14: {
            const roasterProfileOk = Number(get(value, 'time')) === 5 && get(value, 'temp') === 210;
            passed = message === 'OK' && roasterProfileOk;
            break;
          }
          case 16: {
            const roasterProfileOk = Number(get(value, 'time')) === 0 && get(value, 'temp') === 210;
            passed = message === 'OK' && roasterProfileOk;
            break;
          }
          case 17:
            passed = message === 'OK' && Number(value) === 1;
            break;
          case 19:
            passed = message === 'OK' && Number(value) === 5;
            break;
          case 21:
            passed = message === 'OK' && Number(value) === 6;
            break;
          case 3:
          case 5:
          case 7:
          case 9:
          case 11:
          case 13:
          case 15:
          case 18:
          case 20:
          case 22:
            passed = message === 'OK' && value === null;
            break;
          default:
            break;
        }
        return { ...itm, passed };
      })
      .reduce((acc: SelfTestSuite[], curr, index, src) => {
        const prevStage = src[index - 1];
        const isNewSuite = (
          !prevStage ||
          curr.stage_id === 1
        );
        if (isNewSuite) {
          const suite = {
            id: uuid(),
            hostname: curr.hostname,
            start: curr.timestamp,
            end: curr.timestamp,
            passed: curr.passed,
            stages: [curr],
          };
          return [...acc, suite];
        }
        const suiteIndex = acc.findIndex(itm => {
          const lastSuiteStage = [...itm.stages].reverse()[0];
          return (
            lastSuiteStage &&
            lastSuiteStage.hostname === curr.hostname &&
            lastSuiteStage.stage_id === (curr.stage_id - 1)
          );
        });
        return acc.map((itm, index) => {
          if (index === suiteIndex) {
            return {
              ...itm,
              stages: [...itm.stages, curr],
              end: curr.timestamp,
              passed: itm.passed && curr.passed,
            };
          }
          return itm;
        });
      }, [])
      .sort((a, b) => {
        return new Date(a.end) < new Date(b.end) ? 1 : -1
      });
  },
);
export default testSuites;
