import moment from 'moment';
import { get, isArray } from 'lodash';
import { Query } from 'parse';
import * as actions from '../constants';
import { MaintenanceEvent } from '../../models/roasters';
import MaintenanceEventQuery from '../../constants/MaintenanceEventQuery';

export interface MaintenanceEventFromParse {
  attributes: MaintenanceEvent;
}

export const getMaintenanceEvents = (query: Query<typeof MaintenanceEventQuery>) => dispatch => {
  dispatch({ type: actions.GET_MAINTENANCE_EVENTS });
  query
    .lessThanOrEqualTo('createdAt', moment().subtract(2, 'weeks'))
    .find()
    .then((events: MaintenanceEventFromParse[]) => dispatch({
      type: actions.GET_MAINTENANCE_EVENTS_SUCCESS,
      data: { events: events.map(itm => ({ "_id": get(itm, 'id'), ...itm.attributes })) },
    }))
    .catch(error => dispatch({
      type: actions.GET_MAINTENANCE_EVENTS_FAILED,
      data: { error },
    }));
}

export const addMaintenanceEvent = (event: MaintenanceEvent) => dispatch => {
  dispatch({
    type: actions.ADD_MAINTENANCE_EVENTS,
    data: { event },
  });
};

export const updateMaintenanceEvent = (event: MaintenanceEventFromParse) => dispatch => dispatch({
  type: actions.UPDATE_MAINTENANCE_EVENT,
  data: { event: ({ "_id": get(event, 'id'), ...event.attributes }) },
});