import * as React from 'react';
import * as _ from 'lodash';
import styled from '../../helpers/styled';
import { withRouter, RouteChildrenProps } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AppStore } from '../../models/store';
import { PuttyAPI, Tower } from '../../api';
import { PuttyLogItem, LogDataKeys } from '../../models/putty';
import ScrollableTable from '../../components/UI/ScrollableTable';
import Button from '../../components/UI/Button';

const Container = styled.div`
width: 1200px;
margin: 0 auto;
`;

const CheckboxGroup = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
flex-wrap: wrap;
`;

const Checkbox = styled.div`
flex: 1 1 80px;
display: block;
label {
  font-size: 12px;
}
`;

const Table = styled(ScrollableTable)`

`;

const ColumnSwitch: React.FunctionComponent<{
  keys: string[];
  activeKeys: string[];
  onClick: (key: string) => void;
}> = React.memo(props => {
  const onClickHandler = (key: string) => () => props.onClick(key);
  return (
    <CheckboxGroup>
      {
        props.keys.map(key => {
          if (!key) {
            return null;
          }
          return (
            <Checkbox>
              <label>{LogDataKeys[key]}</label>
              <input type="checkbox" checked={_.includes(props.activeKeys, key)} onClick={onClickHandler(key)} />
            </Checkbox>
          )
        })
      }
    </CheckboxGroup>
  );
});

interface Props extends RouteChildrenProps<{ machineId: string }> {

}
interface State {
  logs: any[];
  isLoading: boolean;
  isRefreshing: boolean;
  activeKeys: string[];
}
class PuttyLogsContainer extends React.Component<Props, State> {
  interval: any;
  state = {
    logs: [],
    isLoading: false,
    isRefreshing: false,
    activeKeys: _.times(26).map(key => String(key)),
  }

  get machineId() {
    return _.get(this.props.match, 'params.machineId', null);
  }

  get downloadUrl() {
    return `${Tower.baseUrl}/services/v1/csvputtyLogs?machine=${this.machineId}`;
  }

  toggleDataKey = key => this.setState(state => {
    const activeKeys = _.includes(state.activeKeys, key)
      ? [...state.activeKeys].filter(k => k !== key)
      : [...state.activeKeys, key];
    return {
      ...state,
      activeKeys,
    };
  })

  sanitizeRawLog = (raw: string) => {
    if (!raw.includes("HtrOut") && !raw.includes("m:s")) {
      return raw.split(',');
    }
    return null;
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      PuttyAPI.getLogsForMachine(this.machineId)
        .then(logs => {
          this.setState({ isLoading: false, logs: _.compact(logs.slice(0, 500).map(log => this.sanitizeRawLog(log.raw_data))) })
          this.interval = setInterval(this.refresh, 5000);
        })
        .catch(console.warn);
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refresh = () => {
    if (!this.state.isRefreshing) {
      this.setState({ isRefreshing: true }, () => {
        PuttyAPI.getLogsForMachine(this.machineId)
          .then(logs => {
            this.setState({ isRefreshing: false, logs: _.compact(logs.slice(0, 500).map(log => this.sanitizeRawLog(log.raw_data))) })
          })
          .catch(console.warn);
      })
    }
  }

  render() {
    return (
      <Container>
        <h1>Machine: {this.machineId}</h1>
        <a href={this.downloadUrl} target="_blank">Download CSV</a>
        {
          this.state.logs.length > 0 &&
          <>
            <ColumnSwitch
              onClick={this.toggleDataKey}
              keys={Object.keys(this.state.logs[0])}
              activeKeys={this.state.activeKeys}
            />
            <ScrollableTable
              height="80vh"
              maxHeight="80vh"
              width={1200}
              maxWidth={1200}
            >
              <thead>
                <tr>
                  {
                    _.times(25, key => {
                      return _.includes(this.state.activeKeys, String(key))
                        ? <th>{LogDataKeys[key]}</th> : null
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.logs.map(log => {
                    return (
                      <tr>
                        {
                          Object.keys(log).map(key => _.includes(this.state.activeKeys, key) ? (<td>{log[key]}</td>) : null)
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </ScrollableTable>
          </>
        }
      </Container>
    )
  }
}
const mapStateToProps = (state: AppStore) => ({
  isAuthenticated: state.auth.isAuthenticated,
})
const mapDispatchToProps = (dispatch) => ({
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
export default enhance(PuttyLogsContainer);
