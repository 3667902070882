import * as React from 'react';
import { get, isString } from 'lodash';
import moment from 'moment';
import ReactMapGL, { Marker, Popup, FullscreenControl } from 'react-map-gl';
import { MarkerData, MarkersAndClusters } from '../../redux/selectors/mapMarkers';
import styled from '../../helpers/styled';
import themeColor from '../../helpers/themeColor';

import roasterImage from '../../assets/roaster_pin.png';
import roasterColor from '../../helpers/roasterColor';

import { AppStore } from '../../models/store';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { setFullscreen } from '../../redux/actions';

export interface RoasterMapProps extends RouteComponentProps {
  markers?: MarkerData[];
  isFullscreen: boolean;
  setFullscreen: (isFullscreen) => void;
}

const Pin = styled('div')<{ color: string; transparency: boolean }>`
width: 20px;
height: 40px;
display: block;
cursor: pointer;
background-color: ${props => props.color};
position: relative;
opacity: ${props => props.transparency ? 0.5 : 1};
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 40px;
}
img {
  width: 20px;
  height: 40px;
}
`;

class Markers extends React.Component<{
  markers: MarkerData[];
  clusters: MarkersAndClusters["clusters"];
  onClick: (marker: MarkerData) => () => void;
}> {
  render() {
    return (
      <>
        {
          this.props.markers.map(marker => {
            const lastUpdate = marker.lastEvent ? moment.duration(moment(moment()).diff(marker.lastEvent.createdAt)) : null;
            let isTransparent = !lastUpdate;
            if (lastUpdate && lastUpdate.asMinutes() >= 10) {
              isTransparent = true;
            }
            return (
              <StyledMarker
                key={`roaster_${marker.hostname}`}
                offsetLeft={marker.offsetLeft}
                longitude={marker.longitude}
                latitude={marker.latitude}
              >
                <Pin transparency={isTransparent} color={roasterColor(marker.state)}>
                  <div className="overlay" onClick={this.props.onClick(marker)}></div>
                  <img src={roasterImage} />
                </Pin>
                <div className="preview">{marker.hostname}</div>
              </StyledMarker>
            );
          })
        }
      </>
    )
  }
}

const StyledPopup = styled(Popup)`
z-index: 999;
background-color: ${themeColor('white')};
img {
  margin: 20px;
}
`;

const StyledMarker = styled(Marker)`
z-index: 99;
position: relative;
.preview {
  display: none;
  position: absolute;
  padding: 5px;
  color: ${themeColor('white')};
  background-color: rgba(0,0,0,0.7);
  text-align: center;
  width: 50px;
  font-size: 10px;
  bottom: -24px;
  left: calc(50% - 25px);
}
:hover {
  .preview {
    display: block;
  }
}
`;

const RoastPopup: React.FunctionComponent<{
  latitude: number;
  longitude: number;
  onClose: () => void;
  imageUrl: string;
}> = props => {
  return (
    <StyledPopup
      anchor="bottom"
      offset={{ top: 30 }}
      longitude={props.longitude}
      latitude={props.latitude}
      onClose={props.onClose}
    >
      {
        isString(props.imageUrl)
          ? (
            <img height={200} width={300} src={props.imageUrl} />
          ) : (
            <p>No image data...</p>
          )
      }
    </StyledPopup>
  );
}

interface SpiderData {
  lat: string;
  lng: string;
  markers: MarkerData[];
}
interface State {
  isMapReady: boolean;
  map: any;
  viewport: any;
  popupData: MarkerData | null;
  mapHeight: any;
}
class RoasterMap extends React.Component<RoasterMapProps, State> {
  state = {
    map: null,
    popupData: null,
    isMapReady: false,
    viewport: {
      latitude: 37.0902,
      longitude: -95.7129,
      zoom: 4,
    },
    mapHeight: 0
  };

  showPopup = (marker: MarkerData) => () => this.setState({ popupData: { ...marker } })

  closePopup = () => this.setState({ popupData: null })

  popup = () => {
    const { popupData } = this.state;
    return popupData !== null ? (
      <RoastPopup
        onClose={this.closePopup}
        latitude={+popupData.latitude}
        longitude={+popupData.longitude}
        imageUrl={get(popupData, 'lastImage')}
      />
    ) : null;
  }

  onStyleLoad = map => {
    this.setState({
      map,
      isMapReady: true,
    })
  }

  handleFullscreen = () => {
    this.props.setFullscreen(!this.props.isFullscreen);
  }

  onViewportChange = (viewport) => {
    if (this.props.setFullscreen && this.state.mapHeight > viewport.height){
      this.props.setFullscreen(false);
    }
    this.setState({ viewport, mapHeight: viewport.height })
  }

  render() {
    return (
      <ReactMapGL
        {...this.state.viewport}
        onStyleLoad={this.onStyleLoad}
        width="100vw"
        height="100vh"
        mapboxApiAccessToken="pk.eyJ1Ijoic2ViYXNhbGluZXMiLCJhIjoiY2p1MjdvYnFwMDlrdjQ0cXY1NW42ZmY2bCJ9.08FoXaLXXAUF2jL3d0Eedg"
        onViewportChange={(viewport) => this.onViewportChange(viewport)}
      >
        <div style={{position: 'absolute', right: 10, top: 10}} onClick={this.handleFullscreen}>
          <FullscreenControl container={document.querySelector('body')} />
        </div>
        {this.popup()}
        <Markers
          onClick={this.showPopup}
          markers={this.props.markers}
          clusters={[]}
        />
      </ReactMapGL>
    );
  }
}

const mapStateToProps = (state: AppStore) => ({
  isFullscreen: state.map.isFullscreen
})
const mapDispatchToProps = (dispatch) => ({
  setFullscreen: (isFullscreen) => dispatch(setFullscreen(isFullscreen)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoasterMap));
