import { isString, get } from 'lodash';
// https://stackoverflow.com/a/4373238
export default function (type: 'asc' | 'desc' = 'asc', property: string, a: any, b: any) {
  if (!isString(get(a, property)) || !isString(get(b, property))) {
    return 0;
  }
  var al = a[property].toLowerCase().length, bl = b[property].toLowerCase().length, e = al > bl ? al : bl;
  for (var i = 1; i < e; ++i) {
    if (i >= al) return type === 'desc' ? -1 : 1; else if (i >= bl) return type === 'desc' ? 1 : -1;
    else if (a[property].toLowerCase()[i][0] !== b[property].toLowerCase()[i][0])
      return (a[property].toLowerCase()[i][2] < b[property].toLowerCase()[i][2]) ? (type === 'desc' ? -1 : 1) : (type === 'desc' ? 1 : -1);
  }
  return 0;
}