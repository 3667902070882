import * as React from 'react';
import Parse from 'parse';
import moment, { min } from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { ScaleLoader, BounceLoader } from 'react-spinners';
import { colors } from '../../constants/colors';
import styled from '../../helpers/styled';
import Roaster, { RoasterProps } from '../../components/Roaster';
import { Roaster as RoasterFromApi, SelfTestItem, NetSpeedItem, FullRoasterInfo, BaseRoaster } from '../../models/roasters';
import { getFullRoasterData } from '../../redux/actions';
import { fullRoasterData, isLoadingFullRoasterData } from '../../redux/selectors/fullRoasterData';
import { Content, Container } from '../../components/UI';
import { AppStore } from '../../models/store';
import { RoasterHeading, HeadingRow } from '../../components/RoasterTable';
import SortControl from '../../components/UI/SortControl';
import MaintenanceEventQuery from '../../constants/MaintenanceEventQuery';
import { Tower } from '../../api';

const RoasterList = styled.div`
display: flex;
flex: 1;
flex-direction: column;
width: 100%;
border-radius: 3px;
-webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
-moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.31);
`;

const MainContainer = styled(Container)`
padding: 20px 0;
max-width: 1200px;
width: 100%;
margin: 0 auto;
.heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
${HeadingRow} {
  width: 100%;
}
`;

interface Props {
  roasters: BaseRoaster[];
  getFullRoasterData: any;
  fullRoasterData: FullRoasterInfo[];
  isLoadingFullRoasterData: boolean;
}

interface State {
  tests: SelfTestItem[];
  isLoading: boolean;
  network: NetSpeedItem[];
  error: string | null;
  dump: string | null;
  roasters: RoasterFromApi[];
  fullRoasters: RoasterProps[];
}
class ReportsContainer extends React.Component<Props, State> {
  query = new Parse.Query(MaintenanceEventQuery);
  state: State = {
    isLoading: false,
    roasters: [],
    tests: [],
    network: [],
    error: null,
    dump: null,
    fullRoasters: [],
  };

  componentDidMount() {
    this.props.getFullRoasterData(this.query);
  }

  get downloadUrl() {
    return `${Tower.baseUrl}/roastersCSV`;
  }

  roasters = () => {
    if (this.props.isLoadingFullRoasterData) {
      return null;
    }
    if (!this.props.fullRoasterData.length) {
      return <p style={{ textAlign: 'center' }}>No data</p>
    }
    return this.props.fullRoasterData
      .filter(roaster => !!roaster.Host && !!roaster.Port)
      .map((roaster, index) => (
        <Roaster
          info={roaster}
          key={`roaster_${roaster.Host}_${roaster.Port}`}
          color={index % 2 === 0 ? colors.page : colors.white}
        />
      ))
  }

  render() {
    if (this.props.isLoadingFullRoasterData) {
      return (
        <MainContainer>
          <BounceLoader color={colors.secondary} />
        </MainContainer>
      )
    }
    return (
      <MainContainer>
        <div className="heading">
          <h1>Roaster Reports</h1>
          <a href={this.downloadUrl} target="_blank">Download CSV</a>
          <SortControl />
        </div>
        <RoasterHeading />
        <RoasterList>
          {this.roasters()}
        </RoasterList>
      </MainContainer>
    )
  }
}

const mapStateToProps = (state: AppStore) => ({
  roasters: state.roasters.roasters,
  fullRoasterData: fullRoasterData(state),
  isLoadingFullRoasterData: isLoadingFullRoasterData(state),
});
const mapDispatchToProps = dispatch => ({
  getFullRoasterData: (query: Parse.Query) => dispatch(getFullRoasterData(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
