import * as React from 'react';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppStore } from '../../models/store';
import { Tower } from '../../api';
import { BounceLoader } from 'react-spinners';
import { colors } from '../../constants';

class RoastList extends React.PureComponent<any> {
  state = {
    isLoading: false,
    batches: [],
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      Tower.get<{ roastBatches: { roastBatch: string }[] }>(`/services/v1/roastList?machineId=${this.props.machineId}`)
        .then(res => this.setState({ isLoading: false, batches: res.roastBatches.map(i => i.roastBatch) }))
        .catch(console.warn)
    })
  }

  goToRoastLog = log => () => this.props.history.push(`/roastLog/${log}`, { machineId: this.props.machineId })

  render() {
    if (this.state.isLoading) {
      return <div style={{ margin: '0 auto' }}><BounceLoader color={colors.secondary} /></div>
    }
    return (
      <ul>
        {this.state.batches.map(i => <li><a href="#" onClick={this.goToRoastLog(i)}>{i}</a></li>)}
      </ul>
    );
  }
}
const mapStateToProps = (state: AppStore) => ({

});
const enhance: any = compose(
  withRouter,
  connect(mapStateToProps),
);
export default enhance(RoastList);