import { get } from 'lodash';
import * as actions from '../constants';
import { CustomersReducer } from 'models/store';

const initialState: CustomersReducer = {
  isLoading: false,
  customers: [],
  records: [],
  roasts: [],
};

const customers = (state: CustomersReducer = initialState, action: { type: string, data?: _.Dictionary<any>; }): CustomersReducer => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_CUSTOMER_SATISFACTION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CUSTOMER_SATISFACTION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_CUSTOMER_SATISFACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roasts: [...data.roasts],
        records: [...data.records],
      }
    case actions.GET_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: [...data.customers],
      }
    default:
      return state;
  }
}
export default customers;
