import { get } from 'lodash';
import { CafesReducer } from "../../models/store";
import * as actions from '../constants/cafes';
import { BaseCafe } from "../../models/core";

const initialState: CafesReducer = {
  isLoading: false,
  cafes: [],
};
const cafes = (state: CafesReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_CAFES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CAFES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_CAFES_SUCCESS: {
      const cafes: BaseCafe[] = get(data, 'cafes', []);
      return {
        ...state,
        isLoading: false,
        cafes,
      };
    }
    default:
      return state;
  }
}
export default cafes;
