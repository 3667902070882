export const colors: {
  white: string;
  primary: string;
  secondary: string;
  accent: string;
  text: string;
  page: string;
  red: string;
  green: string;
  black: string;
} = {
  white: '#FFFFFF',
  primary: '#C2B49C',
  secondary: '#5CA959',
  accent: '#7876E0',
  black: '#3A3333',
  text: '#3B3B3B',
  page: '#EDEAE4',
  red: '#E85A4F',
  green: '#5CA959',
};
