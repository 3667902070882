import { User, AppStore } from 'models';
import { get } from 'lodash';
import * as actions from '../constants/auth';
import { BW } from '../../api';
import { getErrorString } from '../../helpers/getErrorString';

export const signIn = (email: string, password: string) => dispatch => {
  dispatch({ type: actions.GET_AUTH_TOKEN });
  return BW.signIn(email, password)
    .then(payload => {
      dispatch({ type: actions.GET_AUTH_TOKEN_SUCCESS, data: { payload } });
    })
  .catch(error => {
    dispatch({ type: actions.GET_AUTH_TOKEN_FAILED, data: { error } });
    // alert(getErrorString(get(error, 'response.data')));
  });
};


export const signInByToken = () => (dispatch, getState: () => AppStore) => {
	dispatch({ type: actions.REFRESH_TOKEN });
	const token = getState().auth.token;
	return BW.post<User>('/auth/signInByToken', { token })
		.then(userInfo => dispatch({ type: actions.REFRESH_TOKEN_SUCCESS, data: { payload: { token, userInfo } } }))
		.catch(err => dispatch({ type: actions.REFRESH_TOKEN_FAILED }));
}


export const signOut = () => ({ type: actions.DESTROY_AUTH_TOKEN });