import * as React from 'react';
import styled, { css } from '../../../helpers/styled';
import scrollbar from '../../../helpers/styles/scrollbar';
import themeColor from '../../../helpers/themeColor';

type ScrollableTableContainerProps = {
  fullSize?: boolean;
  height?: string | number;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
}
const Container = styled('div')<ScrollableTableContainerProps>`
${props => {
    if (props.fullSize) {
      return css`
      height: 100%;
      width: 100%;
    `;
    }
    const {
      height,
      width,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
    } = props;
    return css`
    height: ${() => height ? typeof height === 'string' ? height : `${height}px` : '300px'};
    width: ${() => width ? typeof width === 'string' ? width : `${width}px` : '300px'};
    min-width: ${() => minWidth ? typeof minWidth === 'string' ? minWidth : `${minWidth}px` : '300px'};
    max-width: ${() => maxWidth ? typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px` : '500px'};
    min-height: ${() => minHeight ? typeof minHeight === 'string' ? minHeight : `${minHeight}px` : '300px'};
    max-height: ${() => maxHeight ? typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px` : '500px'};
  `;
}};
width: 100%;
display: flex;
overflow: scroll;
`;

const Heading = styled.th`
`;

const Table = styled.table`
flex: 1;
th {
  font-size: 16px;
  line-height: 24px;
  color: #3B3B3B;
}
div.column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1;
    text-align: center;
  }
}
tbody {
  background: ${themeColor('white')};
  ${scrollbar()}
  tr {
    padding: 10px 0;
    font-size: 16px;
    line-height: 22px;
    color: #3B3B3B;
    border-bottom: 1px solid #C2B49C;
    td {
      border-right: 1px solid #414042;
    }
    td:last-child {
      border-right-color: transparent;
    }
  }
}
`;

interface ScrollableTableProps extends ScrollableTableContainerProps {
  tableProps?: any;
}
const ScrollableTable: React.FunctionComponent<ScrollableTableProps> = React.memo(props => {
  return (
    <Container {...props}>
      <Table cellPadding="10" cellSpacing="0" {...props.tableProps}>{props.children}</Table>
    </Container>
  )
});
export default ScrollableTable;
