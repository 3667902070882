import * as React from 'react';
import { connect } from 'react-redux';
import Parse, { LiveQuerySubscription, Query } from 'parse';
import { Content } from '../../components/UI';
import { AppStore } from '../../models/store';
import RoasterMap from '../../components/RoasterMap';
import { mapMarkers, isLoadingMarkerData, MarkerData } from '../../redux/selectors/mapMarkers';
import { getMapRelatedData, loadQuery, addEvent, updateEvent } from '../../redux/actions';
import { RoastEvent } from '../../models/roasters';
import RoastEventQuery from '../../constants/RoastEventQuery';
import styled from '../../helpers/styled';
import formatParseObject from '../../helpers/formatParseObject';

const MapContainer = styled.div`
flex: 1;
min-height: calc(100% - 50px);
display: flex;
`;

interface Props {
  isLoading: boolean;
  fetchedFirstTime: boolean;
  getMarkers: () => void;
  mapMarkers: MarkerData[];
  addRoastEvent: (event: RoastEvent | RoastEvent[]) => void;
  updateRoastEvent: (event: RoastEvent) => void;
  getRoastEvents: (query: Query) => void;
}

interface State {
  result: any;
  isMapAvailable: boolean;
  markers: MarkerData[];
  roastEvents: RoastEvent[];
  subscribed: boolean;
}

class LiveMapContainer extends React.Component<Props, State> {
  query: any;
  subscription: LiveQuerySubscription;
  constructor(props) {
    super(props);
    this.query = new Parse.Query(RoastEventQuery);
    this.state = {
      result: null,
      markers: [],
      isMapAvailable: false,
      subscribed: false,
      roastEvents: [],
    };
  }

  componentDidMount() {
    this.props.getMarkers();
    this.props.getRoastEvents(
      this.query.descending("updatedAt").limit(100)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.fetchedFirstTime && nextProps.fetchedFirstTime) {
      this.subscribe()
    }
  }

  prepareSubscription = () => {
    this.setState({
      markers: this.props.mapMarkers,
    }, this.subscribe)
  }

  subscribe = async () => {
    this.query.subscribe()
      .then(subscription => {
        this.subscription = subscription;
        this.subscription.on('create', this.onSubscriptionCreate);
        this.subscription.on('update', this.onSubscriptionUpdate);
        this.subscription.on('delete', this.onSubscriptionDelete);
        this.subscription.on('enter', this.onSubscriptionEnter);
        this.subscription.on('leave', this.onSubscriptionLeave);
        this.subscription.on('open', this.onSubscriptionOpen);
        this.subscription.on('close', this.onSubscriptionClose);
        this.setState({
          isMapAvailable: true,
          subscribed: true,
        })
      })
      .catch(err => {
        console.warn(err);
      });
  }

  onSubscriptionCreate = (object: any) => {
    const event = formatParseObject(object);
    this.props.addRoastEvent(event);
  }

  onSubscriptionUpdate = (object: any) => {
    const event = formatParseObject(object);
    this.props.updateRoastEvent(object);
  }

  onSubscriptionDelete = (object: any) => {
  }

  onSubscriptionEnter = (object: any) => {
  }

  onSubscriptionLeave = (object: any) => {
  }

  onSubscriptionOpen = () => {
  }

  onSubscriptionClose = () => {
  }

  render() {
    if (this.props.isLoading) {
      return (
        <Content>
          <p style={{ textAlign: 'center' }}>Preparing map...</p>
        </Content>
      );
    }
    if (!this.props.fetchedFirstTime) {
      return (
        <Content>
          <p style={{ textAlign: 'center' }}>Fetching roasters...</p>
        </Content>
      );
    }
    return (
      <MapContainer>
        <RoasterMap markers={this.props.mapMarkers} />
      </MapContainer>
    )
  }
}
const mapStateToProps = (state: AppStore) => ({
  isLoading: isLoadingMarkerData(state),
  mapMarkers: mapMarkers(state),
  fetchedFirstTime: state.roastEvents.fetchedFirstTime,
});
const mapDispatchToProps = dispatch => ({
  getMarkers: () => dispatch(getMapRelatedData()),
  addRoastEvent: event => dispatch(addEvent(event)),
  updateRoastEvent: event => dispatch(updateEvent(event)),
  getRoastEvents: (query: Query) => dispatch(loadQuery(query)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LiveMapContainer as any);
