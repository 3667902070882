import * as React from 'react';
import styled from '../../../helpers/styled';

export default styled('button')<{ fullWidth?: boolean }>`
width: ${props => props.fullWidth ? '100%' : 'auto'};
padding: 7px;
border-radius: 3px;
background-color: #7876E0;
color: #FFFFFF;
font-size: 14px;
font-weight: 500;
line-height: 19px;
text-align: center;
margin-bottom: 14px;
&:disabled {
  background-color: rgba(58,51,51, .4);
}
`;