import * as React from 'react';
import * as _ from 'lodash';
import styled from '../../helpers/styled';
import { withRouter, RouteChildrenProps } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Chart } from "react-google-charts";
import { AppStore } from '../../models/store';
import { PuttyAPI, Tower } from '../../api';
import { PuttyLogItem, LogDataKeys } from '../../models/putty';
import ScrollableTable from '../../components/UI/ScrollableTable';
import { BounceLoader } from 'react-spinners';
import { RoastLogItem, RoastLogDataKeys, RoastLog } from '../../models/roasts';
import { GoogleDataTableColumn, GoogleDataTableColumnRoleType } from 'react-google-charts/dist/types';
import { colors } from '../../constants';

const Container = styled.div`
width: 1200px;
margin: 0 auto;
`;

const CheckboxGroup = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
flex-wrap: wrap;
`;

const Checkbox = styled.div`
flex: 1 1 80px;
display: block;
label {
  font-size: 12px;
}
`;

const Table = styled(ScrollableTable)`

`;

const ColumnSwitch: React.FunctionComponent<{
  keys: string[];
  activeKeys: string[];
  onClick: (key: string) => void;
}> = React.memo(props => {
  const onClickHandler = (key: string) => () => props.onClick(key);
  return (
    <CheckboxGroup>
      {
        props.keys.map(key => {
          if (!key) {
            return null;
          }
          return (
            <Checkbox>
              <label>{RoastLogDataKeys[key]}</label>
              <input type="checkbox" checked={_.includes(props.activeKeys, key)} onChange={onClickHandler(key)} />
            </Checkbox>
          )
        })
      }
    </CheckboxGroup>
  );
});

interface Props extends RouteChildrenProps<{ machineId: string }> {

}
interface State {
  isLoading: boolean;
  isRefreshing: boolean;
  logs: RoastLogItem[];
  activeKeys: string[];
}
class RoastLogsContainer extends React.Component<Props, State> {
  interval: any = null;
  state = {
    isLoading: false,
    isRefreshing: false,
    logs: [],
    activeKeys: [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
    ],
  }

  get machineId() {
    return _.get(this.props.match, 'params.machineId', null);
  }

  get log() {
    return _.get(this.props.match, 'params.log', null);
  }

  get downloadUrl() {
    return `${Tower.baseUrl}/services/v1/csvroastLogs?roastBatch=${this.log}`;
  }

  get columns() {
    const { activeKeys } = this.state;
    const columns = _.compact([
      _.includes(activeKeys, '0') ? "Time" : null,
      _.includes(activeKeys, '1') ? "HtrOut" : null,
      _.includes(activeKeys, '2') ? "Cat" : null,
      _.includes(activeKeys, '3') ? "Blower" : null,
      _.includes(activeKeys, '4') ? "Inlet" : null,
      _.includes(activeKeys, '5') ? "BeanFr" : null,
      _.includes(activeKeys, '6') ? "DrumBtm" : null,
      _.includes(activeKeys, '7') ? "Exhaust" : null,
      _.includes(activeKeys, '8') ? "Cat2" : null,
      _.includes(activeKeys, '9') ? "AirSpf" : null,
      _.includes(activeKeys, '10') ? "AirPwm" : null,
      _.includes(activeKeys, '11') ? "AuxTgt" : null,
      _.includes(activeKeys, '12') ? "AuxPwm" : null,
      _.includes(activeKeys, '13') ? "CatSpf" : null,
      _.includes(activeKeys, '14') ? "BlwrSpf" : null,
      _.includes(activeKeys, '15') ? "RstSpf" : null,
      _.includes(activeKeys, '16') ? "RstPwm" : null,
      _.includes(activeKeys, '17') ? "RstAut" : null,
      _.includes(activeKeys, '18') ? "Dvtr" : null,
      _.includes(activeKeys, '19') ? "Vfd" : null,
      _.includes(activeKeys, '20') ? "Falling" : null,
      _.includes(activeKeys, '21') ? "Exit" : null,
      _.includes(activeKeys, '22') ? "Load" : null,
      _.includes(activeKeys, '23') ? "FCD" : null,
      _.includes(activeKeys, '24') ? "PreHeat" : null,
      _.includes(activeKeys, '25') ? "Roast" : null,
      _.includes(activeKeys, '26') ? "Cool" : null,
    ]).map(label => {
      return {
        label,
        type: label === 'Time' ? 'string' : 'number' as 'string' | 'number',
      };
    });
    return columns;
  }

  get chartRows() {
    const { logs, activeKeys } = this.state;
    const rows = [
      this.columns,
      ...logs.map(log => {
        let preHeat, roast, cool = 0;
        if (log.record_type == "Preheat") {
          preHeat = 1000;
        }
        if (log.record_type == "Roast") {
          roast = 1000;
        }
        if (log.record_type == "Cool") {
          cool = 1000;
        }
        return [
          _.includes(activeKeys, '0') ? log.adjusted_time : null,
          _.includes(activeKeys, '1') ? log.htrout : null,
          _.includes(activeKeys, '2') ? log.cat : null,
          _.includes(activeKeys, '3') ? log.blower : null,
          _.includes(activeKeys, '4') ? log.inlet : null,
          _.includes(activeKeys, '5') ? log.beanfr : null,
          _.includes(activeKeys, '6') ? log.drumbtm : null,
          _.includes(activeKeys, '7') ? log.exhaust : null,
          _.includes(activeKeys, '8') ? log.cat2 : null,
          _.includes(activeKeys, '9') ? log.airspf : null,
          _.includes(activeKeys, '10') ? log.airpwm : null,
          _.includes(activeKeys, '11') ? log.auxtgt : null,
          _.includes(activeKeys, '12') ? log.auxpwm : null,
          _.includes(activeKeys, '13') ? log.catspf : null,
          _.includes(activeKeys, '14') ? log.blwrspf : null,
          _.includes(activeKeys, '15') ? log.rstspf : null,
          _.includes(activeKeys, '16') ? log.rstpwm : null,
          _.includes(activeKeys, '17') ? log.rstaut : null,
          _.includes(activeKeys, '18') ? log.dvtr : null,
          _.includes(activeKeys, '19') ? log.vfd : null,
          _.includes(activeKeys, '20') ? log.falling : null,
          _.includes(activeKeys, '21') ? log.exitval : null,
          _.includes(activeKeys, '22') ? log.loadval : null,
          _.includes(activeKeys, '23') ? log.fcd : null,
          _.includes(activeKeys, '24') ? preHeat : null,
          _.includes(activeKeys, '25') ? roast : null,
          _.includes(activeKeys, '26') ? cool : null,
        ].filter(i => i !== null);
      }),
    ]
    return rows;
  }

  toggleDataKey = key => this.setState(state => {
    const activeKeys = _.includes(state.activeKeys, key)
      ? [...state.activeKeys].filter(k => k !== key)
      : [...state.activeKeys, key];
    return {
      ...state,
      activeKeys,
    };
  })

  componentDidMount() {
    this.setState({ isLoading: true }, () => Tower.get<RoastLog>(`/services/v1/roastlogs?roastBatch=${this.log}`)
      .then(this.handleResponse)
      .catch(() => this.setState({ isLoading: false }))
    );
  }

  handleResponse = (log: RoastLog) => {
    this.setState({ logs: log.logItems, isLoading: false }, () => {
      this.interval = setInterval(this.refresh, 6000);
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refresh = () => {
    if (!this.state.isRefreshing) {
      this.setState({ isRefreshing: true }, () => {
        Tower.get<RoastLog>(`/services/v1/roastlogs?roastBatch=${this.log}`)
          .then(logs => {
            this.setState({ isRefreshing: false, logs: _.compact(logs.logItems) })
          })
          .catch(console.warn);
      })
    }
  }

  chart = () => {
    return this.state.logs.length ? (
      <Chart
        chartType="LineChart"
        data={this.chartRows}
        options={{
          title: 'Roast Chart',
          legend: { position: 'right' }
        }}
        width="100%"
        height="400px"
        legendToggle
      />
    ) : null;
  }

  render() {
    return (
      <Container>
        <h1>Machine: {this.machineId}</h1>
        <a href={this.downloadUrl} target="_blank">Download CSV</a>
        <ColumnSwitch
          onClick={this.toggleDataKey}
          keys={Object.keys(RoastLogDataKeys)}
          activeKeys={this.state.activeKeys}
        />
        {
          this.state.isLoading ? (
            <div style={{ margin: '20px auto', width: '90px' }}>
              <BounceLoader color={colors.secondary} />
            </div>
          ) : this.chart()
        }
      </Container>
    )
  }
}
const mapStateToProps = (state: AppStore) => ({
  isAuthenticated: state.auth.isAuthenticated,
})
const mapDispatchToProps = (dispatch) => ({
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
export default enhance(RoastLogsContainer);
