import { get } from 'lodash';
import { SelfTestsReducer } from "../../models/store";
import * as actions from '../constants/selfTest';
import { SelfTestItem } from "../../models/roasters";

const initialState: SelfTestsReducer = {
  isLoading: false,
  selfTests: [],
};
const selfTest = (state: SelfTestsReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_SELFTEST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SELFTEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_SELFTEST_SUCCESS: {
      const items: SelfTestItem[] = get(data, 'selfTests', []);
      const selfTests = items
        .filter(itm => ( // make sure we have all data
          itm.hostname &&
          itm.passed !== undefined &&
          itm.timestamp &&
          itm.stage_id &&
          itm.fullResponse
        ));
      return {
        ...state,
        isLoading: false,
        selfTests,
      };
    }
    default:
      return state;
  }
}
export default selfTest;
