import { get, isArray } from 'lodash';
import { Query } from 'parse';
import * as actions from '../constants/roastEvents';
import { RoastEvent } from '../../models/roasters';
import RoastEventQuery from '../../constants/RoastEventQuery';

export interface RoastEventFromParse {
  attributes: RoastEvent;
}

export const loadQuery = (query: Query<typeof RoastEventQuery>) => dispatch => {
  dispatch({ type: actions.GET_ROAST_EVENTS });
  query.find()
    .then((events: RoastEventFromParse[]) => dispatch({
      type: actions.GET_ROAST_EVENTS_SUCCESS,
      data: { events: events.map(itm => ({ "_id": get(itm, 'id'), ...itm.attributes})) },
    }))
    .catch (error => dispatch({
        type: actions.GET_ROAST_EVENTS_FAILED,
        data: { error },
      }));
}

export const addEvent = (event: RoastEvent) => dispatch => {
  dispatch({
    type: actions.ADD_ROAST_EVENTS,
    data: { event },
  });
};

export const updateEvent = (event: RoastEventFromParse) => dispatch => dispatch({
  type: actions.UPDATE_ROAST_EVENT,
  data: { event: ({ "_id": get(event, 'id'), ...event.attributes}) },
});