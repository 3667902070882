import { get } from 'lodash';
import * as actions from '../constants/maintenanceEvents';
import { MaintenanceEventsReducer } from '../../models/store';
import { MaintenanceEvent } from '../../models/roasters';

const initialState: MaintenanceEventsReducer = {
  fetchedFirstTime: false,
  isLoading: false,
  events: [],
};

const MaintenanceEvents = (state: MaintenanceEventsReducer = initialState, action: { type: string, data?: _.Dictionary<any>; }): MaintenanceEventsReducer => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_MAINTENANCE_EVENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_MAINTENANCE_EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_MAINTENANCE_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchedFirstTime: true,
        events: [...state.events, ...data.events],
      }
    case actions.ADD_MAINTENANCE_EVENTS: {
      return {
        ...state,
        isLoading: false,
        events: [...state.events, data.event].slice(),
      };
    }
    case actions.UPDATE_MAINTENANCE_EVENT: {
      const updated: MaintenanceEvent = get(data, 'event', {});
      return {
        ...state,
        events: state.events.map(evt => {
          if (evt.objectId === updated.objectId) {
            return {
              ...evt,
              ...updated,
            };
          }
          return evt;
        }),
      };
    }
    default:
      return state;
  }
}
export default MaintenanceEvents;
