import moment from 'moment';
import { get } from 'lodash';
import { SortReducer } from "../../models/store";
import * as actions from '../constants';
import { SortOption } from '../../components/UI/SortControl';
import { FullRoasterInfo } from '../../models/roasters';
import naturalSort from '../../helpers/naturalSort';

export const defaultSortOptions: SortOption[] = [
  {
    id: 'upload',
    label: 'Upload Speed',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      const speedA = get(a.networkSnap, 'upload', 0);
      const speedB = get(b.networkSnap, 'upload', 0);
      if (speedA > speedB) {
        return type === 'desc' ? 1 : -1;
      } else if (speedB > speedA) {
        return type === 'desc' ? -1 : 1;
      } else {
        return 0;
      }
    }
  },
  {
    id: 'customerSatisfaction',
    label: 'Customer Satisfaction',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      if (a.customerSatisfaction > b.customerSatisfaction) {
        return type === 'desc' ? 1 : -1;
      } else if (b.customerSatisfaction > a.customerSatisfaction) {
        return type === 'desc' ? -1 : 1;
      } else {
        return 0;
      }
    },
  },
  {
    id: 'serialNumber',
    label: 'Serial Number',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      return naturalSort(type, 'Host', a, b);
    }
  },
  {
    id: 'customer',
    label: 'Customer',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      if (a.customer > b.customer) {
        return type === 'desc' ? 1 : -1;
      } else if (b.customer > a.customer) {
        return type === 'desc' ? -1 : 1;
      }
      return 0;
    }
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      const valueA = moment(get(a, 'Timestamp', 0)).valueOf();
      const valueB = moment(get(b, 'Timestamp', 0)).valueOf();
      if (valueA > valueB) {
        return type === 'desc' ? 1 : -1;
      } else if (valueB > valueA) {
        return type === 'desc' ? -1 : 1;
      }
      return 0;
    }
  },
  {
    id: 'chaffCleaning',
    label: 'Last Chaff Cleaning',
    callback: (type: 'asc' | 'desc', a: FullRoasterInfo, b: FullRoasterInfo) => {
      if (a.lastMaintenanceEvent && b.lastMaintenanceEvent) {
        const valueA = moment(a.lastMaintenanceEvent.createdAt).valueOf();
        const valueB = moment(b.lastMaintenanceEvent.createdAt).valueOf();
        if (valueA > valueB) {
          return type === 'desc' ? 1 : -1;
        } else if (valueB > valueA) {
          return type === 'desc' ? -1 : 1;
        }
        return 0;
      } else {
        if (a.lastMaintenanceEvent && !b.lastMaintenanceEvent) {
          return type === 'desc' ? 1 : -1;
        } else if (b.lastMaintenanceEvent && !a.lastMaintenanceEvent) {
          return type === 'desc' ? -1 : 1;
        }
        return 0;
      }
    }
  },
];

const initialState: SortReducer = {
  selectedType: 'asc',
  selectedOption: { ...defaultSortOptions[0] },
  availableOptions: defaultSortOptions,
};

const sort = (state: SortReducer = initialState, action: { type: string, data?: _.Dictionary<any> }): SortReducer => {
  const { type, data } = action;
  switch (type) {
    case actions.SET_SORT_OPTION: {
      const selectedOption = get(data, 'option', state.selectedOption);
      return {
        ...state,
        selectedOption,
      };
    }
    case actions.SET_SORT_TYPE: {
      const selectedType = get(data, 'type', state.selectedType);
      return {
        ...state,
        selectedType,
      };
    }
    default:
      return state;
  }
}
export default sort;
