import { get, includes } from 'lodash';
import { createSelector } from 'reselect';
import { AppStore } from '../../models/store';
import { FullRoasterInfo } from '../../models/roasters';
import testSuites from './testSuites';
import { defaultSortOptions } from '../reducers/sort';
import { IncidentType } from 'models/core';

const roastersSelector = (state: AppStore) => state.roasters.roasters;
const netSpeedSelector = (state: AppStore) => state.netSpeed.netSpeed;
const maintenanceEventsSelector = (state: AppStore) => state.maintenanceEvents.events;
const sortTypeSelector = (state: AppStore) => state.sort.selectedType;
const sortOptionSelector = (state: AppStore) => state.sort.selectedOption;
const customersSelector = (state: AppStore) => state.customers.customers;
const storesSelector = (state: AppStore) => state.stores.stores;
const roastsSelector = (state: AppStore) => state.customers.roasts;
const customerSatisfactionSelector = (state: AppStore) => state.customers.records;

export const fullRoasterData = createSelector(
  roastersSelector,
  netSpeedSelector,
  testSuites,
  maintenanceEventsSelector,
  sortTypeSelector,
  sortOptionSelector,
  customersSelector,
  storesSelector,
  roastsSelector,
  customerSatisfactionSelector,
  (
    roasters,
    speeds,
    suites,
    events,
    sortType,
    sortOption,
    customers,
    stores,
    roastRecords,
    allIncidents,
  ): FullRoasterInfo[] => {
    return roasters.map(roaster => {
      const matchByHostname = (itm: { hostname: string }) => itm.hostname === roaster.Host;
      const sortByTimestamp = (a: { timestamp: string | number }, b: { timestamp: string | number }) => (
        new Date(a.timestamp).valueOf() > new Date(b.timestamp).valueOf() ? -1 : 1
      );
      const lastEventIndex = events.sort((a, b) => {
        return new Date(a.createdAt).valueOf() > new Date(b.createdAt).valueOf() ? -1 : 1;
      }).findIndex(itm => itm.serialNumber === roaster.serialNumber && (itm.actionId === 1 || itm.actionId === 2));
      const lastMaintenanceEvent = lastEventIndex < 0
        ? null : events[lastEventIndex];
      const netSpeed = speeds.filter(matchByHostname).sort(sortByTimestamp);
      const testSuites = suites.filter(matchByHostname);
      const networkSnap = {
        ping: get(netSpeed, '[0].server.ping', null),
        upload: get(netSpeed, '[0].speeds.upload', null),
        download: get(netSpeed, '[0].speeds.download', null),
      };
      const passedLastTest = testSuites[0]
        ? testSuites[0].passed : null;
      const store = stores.find(i => i.roaster === roaster.Host) || null;
      const cafe = store ? store.cafe : null;
      const customer = customers.find(itm => itm.cafe === cafe);
      const roasts = roastRecords.filter(record => record.machine === roaster.Host);
      const customerIncidents = allIncidents.filter(item => cafe && item.cafe === cafe);
      const happy = customerIncidents.filter(item => item.type === IncidentType.Happy);
      const customerSatisfaction = (happy.length * 100) / customerIncidents.length;
      return {
        ...roaster,
        roasts,
        incidents: customerIncidents,
        customer: customer ? customer.customer || customer.id : 'None',
        customerSatisfaction: Number.isNaN(customerSatisfaction) ? 0 : customerSatisfaction,
        latitude: store ? store.latitude : null,
        longitude: store ? store.longitude : null,
        netSpeed,
        testSuites,
        networkSnap,
        passedLastTest,
        lastMaintenanceEvent,
      };
    })
      .sort((a, b) => {
        const callback = get(defaultSortOptions.find(itm => itm.id === sortOption.id), 'callback', (type, a, b) => 0);
        return callback(sortType, a, b);
      })
  },
);

const isLoadingRoastersSelector = (state: AppStore) => state.roasters.isLoading;
const isLoadingSelfTestsSelector = (state: AppStore) => state.selfTest.isLoading;
const isLoadingNetSpeedSelector = (state: AppStore) => state.netSpeed.isLoading;
const isLoadingMaintenanceEventsSelector = (state: AppStore) => state.maintenanceEvents.isLoading;
const isLoadingStoresSelector = (state: AppStore) => state.stores.isLoading;
const isLoadingCustomerDataSelector = (state: AppStore) => state.customers.isLoading;

export const isLoadingFullRoasterData = createSelector(
  isLoadingRoastersSelector,
  isLoadingSelfTestsSelector,
  isLoadingNetSpeedSelector,
  isLoadingMaintenanceEventsSelector,
  isLoadingStoresSelector,
  isLoadingCustomerDataSelector,
  (
    roasters,
    selfTests,
    netSpeed,
    maintenanceEvents,
    stores,
    customerData,
  ) => {
    return (
      roasters
      || selfTests
      || netSpeed
      || maintenanceEvents
      || stores
      || customerData
    );
  },
);
