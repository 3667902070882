const TestLabels = {
  1: '1. Verify Roaster serial number',
  2: '2. Verify Roaster State before start working',
  3: '3. Set Roaster Preheat temperature',
  4: '3a. Verify Preheat temperature',
  5: '4. Set Roaster state to 4',
  6: '4a. Verify Roaster state equal 4',
  7: '5. Set Roaster profile 0 to 200',
  8: '5a. Verify Roaster Profile 0',
  9: '6. Set Roaster profile 1 to 220',
  10: '6a. Verify Roaster Profile 1',
  11: '7. Set Roaster profile 2 to 240',
  12: '7a. Verify Roaster Profile 2',
  13: '8. Set Roaster profile 3 to 210',
  14: '8.a Verify Roaster Profile 3',
  15: '9. Set Roaster profile 4 to 210',
  16: '9a. Verify Roaster Profile 4',
  17: '10. Verify Roaster Preheat temp = True',
  18: '11. Set Roaster state to 5',
  19: '11a. Verify Roaster state equal 5',
  20: '12. Set Roaster state to 6',
  21: '12a. Verify Roaster state equal 6',
  22: '13. Set Roaster state to 7',
};
export default TestLabels;
