import { get } from 'lodash';
import { NetSpeedReducer } from "../../models/store";
import * as actions from '../constants/netSpeed';
import { NetSpeedItem } from "../../models/roasters";

const initialState: NetSpeedReducer = {
  isLoading: false,
  netSpeed: [],
};

const netSpeed = (state: NetSpeedReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_NETSPEED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_NETSPEED_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_NETSPEED_SUCCESS: {
      const items: NetSpeedItem[] = get(data, 'netSpeed', []);
      const netSpeed = items
        .filter(itm => ( // make sure we have all data
          itm.hostname &&
          itm.timestamp
        ));
      return {
        ...state,
        isLoading: false,
        netSpeed,
      };
    }
    default:
      return state;
  }
}
export default netSpeed;
