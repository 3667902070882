export interface PuttyLogItem {
  raw_data: string;
  putty_log_id: number;
  machine_prefix: string;
  inserted_timestamp: string;
}

export const LogDataKeys = {
  0: "State",
  1: "Time",
  2: "HtrOut",
  3: "Cat1",
  4: "Blower",
  5: "Inlet",
  6: "BeanFr",
  7: "DrumBtm",
  8: "Exhast",
  9: "Cat2",
  10: "AirSPF",
  11: "AirPWM",
  12: "auxTgt",
  13: "auxPWM",
  14: "CatSPF",
  15: "BlwrSPF",
  16: "RstSPF",
  17: "RstPWM",
  18: "RstAut",
  19: "Dvtr",
  20: "VFD",
  21: "Falling",
  22: "Exit",
  23: "Load",
  24: "RstErr",
  25: "Hopper",
  26: "Blank",
}