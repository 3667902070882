import * as _ from 'lodash';
import { getSelfTests } from './selfTest';
import { getNetSpeed } from './netSpeed';
import * as actions from '../constants/roasters';
import { BaseRoaster } from '../../models/roasters';
import { Tower } from '../../api';
import { getMaintenanceEvents } from './maintenanceEvents';
import { getStores } from './stores';
import { getCafes } from './cafes';
import { getCustomerSatisfactions, getCustomers } from './customers';

export const getRoasters = () => dispatch => {
  dispatch({ type: actions.GET_ROASTERS });
  Tower.get<BaseRoaster[]>('/roasters-db')
    .then(roasters => {
      dispatch({ type: actions.GET_ROASTERS_SUCCESS, data: { roasters } });
    })
    .catch(error => dispatch({ type: actions.GET_ROASTERS_FAILED, data: { error } }));
}

export const getFullRoasterData = (query?: Parse.Query) => dispatch => {
  dispatch(getRoasters());
  dispatch(getNetSpeed());
  dispatch(getSelfTests());
  dispatch(getCustomers());
  dispatch(getStores());
  dispatch(getCustomerSatisfactions());
  if (query) {
    dispatch(getMaintenanceEvents(query));
  }
}

export const getMapRelatedData = () => dispatch => {
  dispatch(getRoasters());
  dispatch(getStores());
  // dispatch(getNetSpeed());
}