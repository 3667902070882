import moment from 'moment';
import { get, isArray } from 'lodash';
import { Query } from 'parse';
import * as actions from '../constants';

import { CustomerSatisfaction, BaseCustomer } from 'models/core';
import { CustomerSatisfactionQuery, RoastLogQuery, } from 'constants/queries';
import { BW } from 'api';

export interface CustomerSatisfactionFromParse {
  attributes: CustomerSatisfaction;
}

export const getCustomers = () => async dispatch => {
  dispatch({ type: actions.GET_CUSTOMERS });
  BW.get<BaseCustomer>('/customers')
    .then(customers => {
      dispatch({ type: actions.GET_CUSTOMERS_SUCCESS, data: { customers } });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_CUSTOMERS_FAILED,
        data: { error },
      });
    })
}

export const getCustomerSatisfactions = () => async dispatch => {
  dispatch({ type: actions.GET_CUSTOMER_SATISFACTION });
  try {
    const records = await new Query(CustomerSatisfactionQuery)
      .greaterThanOrEqualTo('createdAt', moment().subtract(2, 'weeks').toDate())
      .find();
    const roasts = await new Query(RoastLogQuery)
      .greaterThanOrEqualTo('createdAt', moment().subtract(2, 'weeks').toDate())
      .find();
    dispatch({
      type: actions.GET_CUSTOMER_SATISFACTION_SUCCESS,
      data: {
        records: records.map(itm => ({ id: get(itm, 'id'), ...itm.attributes })),
        roasts: roasts.map(itm => ({ id: get(itm, 'id'), ...itm.attributes })),
      },
    });
  } catch (error) {
    dispatch({
      type: actions.GET_CUSTOMER_SATISFACTION_FAILED,
      data: { error },
    });
  }
}