import axios from 'axios';
import * as _ from 'lodash';
import * as actions from '../constants/selfTest';
import { SelfTestItem } from '../../models/roasters';
import { Tower } from '../../api';

export const getSelfTests = () => dispatch => {
  dispatch({ type: actions.GET_SELFTEST });
  Tower.get<SelfTestItem[]>('/selftest')
    .then(selfTests => {
      dispatch({ type: actions.GET_SELFTEST_SUCCESS, data: { selfTests } });
    })
    .catch(error => dispatch({ type: actions.GET_SELFTEST_FAILED, data: { error } }));
}