import * as React from 'react';
import moment from 'moment';
import { SelfTestItem } from '../../models/roasters';
import { Table } from '../UI';
import { TestResult } from '../Roaster';
import TestLabels from '../../constants/testLabels';

const TestStages: React.FunctionComponent<{ items: SelfTestItem[] }> = props => {
  return (
    <Table noMargin>
      <thead>
        <tr>
          <th></th>
          <th>Test</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {
          props.items.map((stage: SelfTestItem) => {
            const label = TestLabels[stage.stage_id];
            return (
              <tr>
                <td align="center"><TestResult passed={stage.passed} /></td>
                <td>{label}</td>
                <td>{moment(stage.timestamp).format('MM-DD-YYYY hh:mm:ss')}</td>
              </tr>
            );
          })
        }
      </tbody>
    </Table>
  );
}
export default TestStages;
