import { get } from 'lodash';
import * as actions from '../constants/roastEvents';
import { RoastEventsReducer } from '../../models/store';
import { RoastEvent } from '../../models/roasters';

const initialState: RoastEventsReducer = {
  fetchedFirstTime: false,
  isLoading: false,
  events: [],
};

const roastEvents = (state: RoastEventsReducer = initialState, action: { type: string, data?: _.Dictionary<any>; }): RoastEventsReducer => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_ROAST_EVENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ROAST_EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_ROAST_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchedFirstTime: true,
        events: [...state.events, ...data.events],
      }
    case actions.ADD_ROAST_EVENTS: {
      return {
        ...state,
        isLoading: false,
        events: [data.event, ...state.events].slice(),
      };
    }
    case actions.UPDATE_ROAST_EVENT: {
      const updated: RoastEvent = get(data, 'event', {});
      return {
        ...state,
        events: state.events.map(evt => {
          if (evt._id === updated._id) {
            return {
              ...evt,
              ...updated,
            };
          }
          return evt;
        }),
      };
    }
    default:
      return state;
  }
}
export default roastEvents;
