import { RoastersReducer } from "../../models/store";
import * as actions from '../constants/roasters';

const initialState: RoastersReducer = {
  isLoading: false,
  roasters: [],
};
const roasters = (state: RoastersReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch(type) {
    case actions.GET_ROASTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ROASTERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_ROASTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roasters: [...data.roasters],
      };
    default:
      return state;
  }
}
export default roasters;
