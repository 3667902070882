import { get } from 'lodash';
import { MapReducer } from "../../models/map";
import * as actions from '../constants/map';

const initialState: MapReducer = {
  isFullscreen: false,
};
const map = (state: MapReducer = initialState, action: { type: string, data?: _.Dictionary<any> }) => {
  const { type, data } = action;
  switch (type) {
    case actions.SET_FULLSCREEN:
      return {
        ...state,
        isFullscreen: data.isFullscreen,
      };
    default:
      return state;
  }
}
export default map;
