import { css } from '../styled';

export default () => css`
::-webkit-scrollbar-track {
    height: 5px;
    background-color: transparent;
}
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 2px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    height: 5px;
    width: 5px;
    border-radius: 2px;
    background-color: #C2B49C;
}
`;