import { BaseRoaster } from "./roasters";

export interface BaseStore {
  _id: string;
  name: string;
  cafe: string;
  roaster?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface BaseCafe {
  _id: string;
  cafe?: string;
  name: string;
  type?: string;
  createdAt?: string;
}

export interface BaseCustomer {
  id: string;
  cafe: string;
  version: string;
  beans?: string[];
  customer?: string;
  roastProfileVerification: boolean;
  terminationOptions: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Store {
  _id: string;
  name: string;
  cafe: BaseCafe;
  roaster?: BaseRoaster;
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface CustomerSatisfaction {
  createdAt: Date;
  id: string;
  cafe: string;
  appVersion: string;
  createdBy: string;
  type: IncidentType;
  roast?: ParsePointer;
}

type ParsePointer = {
  objectId: string;
};

export enum IncidentType {
  "Sad" = 0,
  "Happy" = 1,
  "CustomerKiosk" = 2,
  "SalesforceTicket" = 3,
}

export const CustomerSatisfactionType = {
  0: "Sad",
  1: "Happy",
  2: "CustomerKiosk",
  3: "SalesforceTicket",
};

export interface RoastLogRecord {
  id: string;
  MAE0?: string;
  MAE1?: string;
  RMSE0?: string;
  RMSE1?: string;
  machine: string;
  cafe: string;
  dropTime: number;
  serialNumber: string;
  profile?: string;
  data?: string;
  date: Date;
  passed?: boolean;
  state: number;
  roaster?: string;
  mainRoastStats?: string;
  measurements: string;
  migrated?: boolean;
  outputWeight: number;
  type?: string;
  forTest: boolean;
  createdAt: Date;
  firmware?: string;
  favorite?: boolean;
  inputWeight: number;
  firstDropStats?: string;
}