import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Container, Content, Table } from '../../components/UI';
import testSuites, { SelfTestSuite } from '../../redux/selectors/testSuites';
import { AppStore } from '../../models/store';
import { getSelfTests } from '../../redux/actions';
import moment from 'moment';
import { TestResult } from '../../components/Roaster';
import TestStages from '../../components/TestStages';
import TestSuite from '../../components/TestSuite';
import styled from '../../helpers/styled';

const List = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`;

interface Props {
  getSelfTests: () => void;
  isLoading: boolean;
  testSuites: SelfTestSuite[];
};
interface State {

};
class SelfTestsContainer extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getSelfTests();
  }

  shouldComponentUpdate(newProps) {
    if (
      newProps.testSuites.length !== this.props.testSuites.length ||
      newProps.isLoading !== this.props.isLoading
    ) {
      return true;
    }
  }

  suites = () => {
    return this.props.isLoading ? (
      <p style={{ textAlign: 'center' }}>Loading...</p>
    ) : (
        <List>
          {this.props.testSuites.map(itm => <TestSuite suite={itm} />)}
        </List>
      )
  }

  render() {
    console.log(this.props.testSuites);
    return (
      <Container>
        <h1>Self Tests</h1>
        <Content>
          {this.suites()}
        </Content>
      </Container>
    );
  }
}
const mapStateToProps = (state: AppStore) => ({
  isLoading: state.selfTest.isLoading,
  testSuites: testSuites(state),
});
const mapDispatchToProps = dispatch => ({
  getSelfTests: () => dispatch(getSelfTests()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelfTestsContainer);
