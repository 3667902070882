import * as React from 'react';
import moment from 'moment';
import { TestResult, LabeledInfo } from '../Roaster';
import styled from '../../helpers/styled';
import TestStages from '../TestStages';
import { SelfTestSuite } from '../../redux/selectors/testSuites';


const Container = styled.div`
background: ${props => props.theme.pallette.page};
display: flex;
flex: 1;
flex-direction: column;
margin-bottom: 20px;
`;

const Title = styled.div`
/* align-self: stretch; */
display: flex;
flex-direction: column;
justify-content: center;
padding: 10px;
background: ${props => props.theme.pallette.secondary};
div.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
h5 {
  color: ${props => props.theme.pallette.white};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-block-end: 0;
  margin-block-start: 0;
}
`;

const Content = styled.div`
padding: 14px;
flex: 1;
`;

const SerialContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
color: ${props => props.theme.pallette.white};
.label {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}
.serial {
  font-size: 12px;
  font-weight: bold;
}
`;

const TestSuite: React.FunctionComponent<{ showHostname?: boolean; suite: SelfTestSuite }> = props => {
  const [isOpen, toggle] = React.useState(false);
  const onClick = () => toggle(!isOpen);
  return (
    <Container>
      <Title onClick={onClick}>
        {
          props.showHostname !== false &&
          <h5>Hostname: {props.suite.hostname}</h5>
        }
        <div className="container">
          <LabeledInfo label="Ended" value={moment.unix(props.suite.end).fromNow()} />
          <LabeledInfo label="" value={<TestResult passed={props.suite.passed} />} />
        </div>
      </Title>
      {
        isOpen &&
        <TestStages items={props.suite.stages} />
      }
    </Container>
  );
}
export default TestSuite;
