export interface RoastLogItem {
  airpwm: number;
  airspf: number;
  auxpwm: number;
  auxtgt: number;
  beanfr: number;
  blower: number;
  blwrspf: number;
  cat: number;
  cat2: number;
  catspf: number;
  drumbtm: number;
  dvtr: number;
  exhaust: number;
  exitval: number;
  falling: number;
  hopper: number;
  htrout: number;
  inlet: number;
  loadval: number;
  vfd: number;
  rstaut: number;
  rsterr: number;
  rstpwm: number;
  rstspf: number;
  putty_info_id: number;
  event_time: string;
  machine_prefix: string;
  record_type: string;
  roastBatch: string;
  unformatted_data: string;
  inserted_timestamp: string;
  adjusted_time: string;
}

export interface RoastLog {
  logItems: RoastLogItem[];
}

export const RoastLogDataKeys = {
  0: "Time",
  1: "HtrOut",
  2: "Cat",
  3: "Blower",
  4: "Inlet",
  5: "BeanFr",
  6: "DrumBtm",
  7: "Exhaust",
  8: "Cat2",
  9: "AirSpf",
  10: "AirPwm",
  11: "AuxTgt",
  12: "AuxPwm",
  13: "CatSpf",
  14: "BlwrSpf",
  15: "RstSpf",
  16: "RstPwm",
  17: "RstAut",
  18: "Dvtr",
  19: "Vfd",
  20: "Falling",
  21: "Exit",
  22: "Load",
  23: "FCD",
  24: "PreHeat",
  25: "Roast",
  26: "Cool",
}